<template>
  <header
    class="navbar-container tw-w-full hidden-xs-only tw-pl-10 tw-pr-20 tw-top-0 tw-z-10"
    :class="{ shadow: isFeed, cityNavbar: isCity }"
  >
    <TripBasket :drawerState="drawer" />
    <div v-if="isCity" :fluid="fullWidth" :class="{ 'app-padding': fullWidth }">
      <div
        class="tw-grid tw-grid-cols-6 tw-justify-between tw-h-full tw-items-center"
      >
        <router-link
          class="tw-text-left"
          :class="['tw-font-logo hideLogo', 'logo', { dark: darkLogo }]"
          :to="{ name: 'LandingPage' }"
        >
          <LogoWhite />
        </router-link>

        <div class="tw-items-center tw-col-span-3 tw-pl-20">
          <v-tabs v-model="tab">
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <div class="tw-flex tw-flex-row tw-items-center tw-col-span-2">
          <router-link class="menu-item design" :to="{ name: 'OurTrips' }">
            Our Trips
          </router-link>

          <router-link
            class="menu-item design"
            :to="{ name: 'CityDestinations' }"
          >
            Destinations
          </router-link>

          <router-link
            class="tw-flex tw-cursor-pointer tw-flex-row tw-rounded-full tw-py-4 tw-px-4 login-button"
            v-if="!showSignOut"
            :to="{ name: 'SignIn' }"
          >
            Sign in
          </router-link>

          <router-link class="menu-item" v-else :to="{ name: 'SignOut' }">
            Sign Out
          </router-link>
          <div
            v-if="isCity || isFeed"
            @click="drawer = !drawer"
            class="tw-flex tw-cursor-pointer tw-flex-row greyButton tw-rounded-full menu-item tw-py-4 tw-px-4"
          >
            <BasketWhite class=" tw-w-6 tw-h-6" />
            <div class="grey-item tw-pt-1">{{ totalCartCount }} items</div>
          </div>

          <div
            @click="drawer = !drawer"
            v-else
            class="tw-flex tw-flex-row tw-cursor-pointer itemButton tw-rounded-full menu-item tw-py-4 tw-px-4"
          >
            <BasketPink class=" tw-w-6 tw-h-6" />
            <div class="cart-item tw-pt-1">{{ totalCartCount }} items</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else :fluid="fullWidth" :class="{ 'app-padding': fullWidth }">
      <div class="tw-flex tw-justify-between tw-h-full tw-items-center">
        <router-link
          class="tw-text-left"
          v-if="isCity"
          :class="['tw-font-logo hideLogo', 'logo', { dark: darkLogo }]"
          :to="{ name: 'LandingPage', query: {mode: 'Discover'} }"
        >
          <LogoWhite />
        </router-link>
        <router-link
          v-else
          :class="['tw-font-logo', 'logo', { dark: darkLogo }]"
          :to="{ name: 'LandingPage', query: {mode: 'Discover'} }"
        >
          <Logo />
        </router-link>
          <div class="nav-container" :class="{ dark: darkMenu }">
            <!-- <div class="feedback">
                <router-link class="menu-item" :to="{ name: 'Feedback' }">
                  Give Feedback
                </router-link>
              </div> -->

            <div class="tw-flex tw-flex-row tw-items-center tw-col-span-2">
              <router-link class="menu-item design" :to="{ name: 'OurTrips' }">
                Our Trips
              </router-link>

              <router-link
                class="menu-item design"
                :to="{ name: 'CityDestinations' }"
              >
                Destinations
              </router-link>

              <router-link
                class="tw-flex tw-cursor-pointer tw-flex-row tw-rounded-full tw-py-4 tw-px-4 login-button"
                v-if="!showSignOut"
                :to="{ name: 'SignIn' }"
              >
                Sign in
              </router-link>

              <router-link class="menu-item" v-else :to="{ name: 'SignOut' }">
                Sign Out
              </router-link>
              <div
                v-if="isCity || isFeed"
                @click="drawer = !drawer"
                class="tw-flex tw-cursor-pointer	 tw-flex-row greyButton tw-rounded-full menu-item tw-py-4 tw-px-4"
              >
                <BasketWhite class=" tw-w-6 tw-h-6" />
                <div class="grey-item tw-pt-1">{{ totalCartCount }} items</div>
              </div>

              <div
                @click="drawer = !drawer"
                v-else
                class="tw-flex tw-flex-row tw-cursor-pointer itemButton tw-rounded-full menu-item tw-py-4 tw-px-4"
              >
                <BasketPink class=" tw-w-6 tw-h-6" />
                <div class="cart-item tw-pt-1">{{ totalCartCount }} items</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import TripBasket from '@/views/shoppingCart/TripBasket.vue';
import BasketPink from '@/assets/navbar/basket.svg';
//import Basket from '@/assets/navbar/basket.svg';
import BasketWhite from '@/assets/navbar/basket2.svg';
import Logo from '@/assets/logo/logo.svg';
import LogoWhite from '@/assets/logo/logoWhite.svg';
//import DiscoverCity from '@/views/CityPage/DiscoverCity';

export default {
  name: 'navbar',
  components: {
    TripBasket,
    //Basket,
    BasketPink,
    BasketWhite,
    Logo,
    LogoWhite
    //DiscoverCity,
  },
  props: {
    darkLogo: {
      type: Boolean,
      default: false
    },
    darkMenu: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    bottomLine: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      city: null,
      cartWhite: this.getBackground('whiteBasket.png'),
      cartBlack: this.getBackground('blackBasket.png'),
      displayLogo: this.getLogo('logo.png'),
      tab: 1,
      items: [ 'Booking', 'Discover', 'Map' ]
    };
  },
  methods: {
    getBackground: name => ({
      backgroundImage: `url(${require('/src/assets/navbar/' + name)})`
    }),
    getLogo: name => ({
      logoImage: `url(${require('/src/assets/logo/' + name)})`
    }),
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/data',
      loggedIn: 'user/loggedIn'
    }),
    ...mapGetters([ 'totalCartCount' ]),
    isLanding() {
      return this.$route.name === 'LandingPage';
    },
    showSignOut() {
      return (
        this.loggedIn &&
        (this.$store.state.user.data
          ? this.$store.state.user.data.emailVerified
          : false)
      );
    },
    isFeed() {
      return this.$route.name === 'DestinationFeed';
    },
    isExpressCheckout() {
      console.log(this.$route);
      return this.$route.name === 'ExpressCheckout';
    },
    isCity() {
      return this.$route.name === 'DestinationPage';
    },
    mode() {
      return this.$route.query.mode;
    },
    drawer: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        this.$store.commit('toggleDrawer', v);
      }
    }
  },
  mounted() {
    if (!this.isMobile) {
      this.$router.push({
        query: { ...this.$route.query, mode: this.items[this.tab] }
      });
    }
  },
  watch: {
    tab(value) {
      this.$router.push({
        query: { ...this.$route.query, mode: this.items[value] }
      });
    },
    mode(value) {
      this.tab = this.items.findIndex(item => value === item);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';

.icon {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: cover;
  margin: 16px 12px;
}

.basket-black {
  color: #000 !important;
}

.basket-white {
  color: #fff !important;
}

.feedback {
  @include respond-to(md-xl) {
    @apply tw-border-2 tw-border-primary tw-py-4 tw-pr-11 tw-justify-center tw-items-center tw-rounded-full;
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
}

.cityNavbar {
  border-bottom: 2px solid rgba(229, 229, 229, 0.2);
  position: absolute;
  //background: linear-gradient(180deg, #000000 -9.62%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0.604167) 32.81%,
    rgba(0, 0, 0, 0.260417) 69.79%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navbar-container {
  height: 100px;
  padding-top: 2rem;
  .logo {
    width: 14.4vw;
    height: 3vw;
    font-size: 44px;
    color: white;
    padding-bottom: 15px;
    @include respond-to(xs) {
      font-size: 35px;
    }
    margin-left: 2rem;
  }

  .active {
    text-decoration: underline;
  }

  .betaLogo {
    font-size: 20px;
    float: right;
    margin-top: 10px;
  }

  .dark {
    &.logo {
      color: black;
    }

    .menu-item,
    .dropdown {
      color: #28262e;
    }

    .bottom-line {
      border-color: $gray-main;
    }
  }

  .itemButton {
    background: #ffe4ec !important;
    color: #d62a53 !important;
  }

  .greyButton {
    background: rgba(255, 255, 255, 0.2) !important;
    margin-bottom: 5px;
  }

  .grey-item {
    font-family: 'PoppinsRegular';
    font-size: 0.75rem;
    color: white;
    @apply tw-leading-4 tw-mt-1;

    @screen md {
      @apply tw-mt-0 tw-ml-2;
    }

    .dark {
      color: $gray-main;
    }
  }

  .hideLogo {
    @include respond-to(xs) {
      visibility: hidden;
    }
  }

  .dropdown {
    color: white;
    @apply tw-mb-4;
  }

  .menu-item {
    font-family: 'PoppinsRegular';
    font-size: 1rem;
    //padding-bottom: 5px;
    @apply tw-ml-11 tw-font-semibold tw-text-white;

    @screen lg {
      @apply tw-text-sm;
    }
  }

  .design:hover {
      color: #747378;
  }

  .cart-item {
    font-family: 'PoppinsRegular';
    font-size: 0.75rem;
    color: #d62a53;
    @apply tw-leading-4 tw-mt-1;

    @screen md {
      @apply tw-mt-0 tw-ml-2;
    }

    .dark {
      color: $gray-main;
    }
  }

  .nav-container {
    @apply tw-flex tw-flex-row tw-items-center;

    @include respond-to(xs) {
      display: none !important;
    }
  }

  .bottom-line {
    border-bottom: 1px solid white;
  }

  .shoppingcart-item {
    @apply tw-bg-cover tw-bg-center tw-w-6 tw-h-6 tw-mx-auto;

    @screen md {
      width: 24px;
      height: 24px;
      margin-left: 45px;
    }
  }
}

.login-button {
  background: #ffffff21;
  font-family: PoppinsMedium;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin-left: 44px;
  margin-right: -30px;
  margin-bottom: 5px;
  color: #ffffff;
  height: 56px;
}

.dark {
  .login-button {
    color: #000000;
    background: #f1f1f1;
  }
}

.v-tabs::v-deep .theme--light {
  background-color: transparent !important;
}
.v-tabs::v-deep .v-tabs-bar {
  height: 7vh !important;
}
.v-tabs::v-deep .v-tab--active {
  border-bottom: 5px solid white !important;
}
.primary--text {
  color: white !important;
}
.v-tabs::v-deep .v-tab {
  min-width: 5vw !important;
  max-width: unset !important;
  margin: 0 auto;
  padding: unset !important;
  text-transform: unset !important;
  color: #efefef !important;
  font-family: 'PoppinsRegular' !important;
}
</style>
