<template>
  <div v-if="drawerState">
    <div class="overlay" @click="toggleDrawer(!drawerState)" />
    <div class="trip-basket-container" v-if="drawerState">
      <div class="flex md:tw-hidden tw-mt-5">
        <button
          type="button"
          class="close"
          @click="toggleDrawer(!drawerState)"
        ></button>
      </div>
      <ShoppingCard />
    </div>
  </div>
</template>

<script>
import ShoppingCard from '@/views/shoppingCart/ShoppingCard.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TripBasket',
  components: {
    ShoppingCard
  },
  computed: {
    ...mapGetters([ 'drawerState' ])
  },
  watch: {
    drawerState: function() {
      if (this.drawerState) {
        document.querySelector('html')?.classList.add('modal-open');
      } else {
        document.querySelector('html')?.classList.remove('modal-open');
      }
    }
  },
  methods: {
    ...mapActions([ 'toggleDrawer' ])
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.action-bar {
  @apply tw-mb-4 tw-flex tw-items-center tw-w-full tw-justify-center;

  flex-grow: 0.5;
  margin-top: 1.25rem;
  @include respond-to(xs) {
    flex-grow: 1;
  }
  .actionButton {
    font-size: 0.7rem !important;
    border-radius: 10px;
    min-width: 50px !important;
  }
}

.trip-basket-container {
  @apply tw-bg-white tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-h-screen tw-w-screen tw-flex tw-flex-col tw-overflow-y-auto;
  z-index: 2147483647;

  @screen md {
    background-color: #f7f7f7;
    width: 43%;
    min-width: 482px;
  }
}

.overlay {
  @apply tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-h-screen tw-w-screen;
  z-index: 2147483646;
  animation: 0.25s ease-out 0s 1 overlay;

  @screen md {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.close {
  position: absolute;
  right: 32px;
  top: 12px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #000000;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(0);
  }
}

.trip-basket-container {
  animation: 0.3s ease-out 0s 1 slideIn;
}
</style>
